<template>
  <div class="flex flex-column align-items-center justify-content-center">
    <div
      id="contentList"
      class="align-items-center justify-content-center"
      style="display: flex; margin-bottom: 25px"
    >
      <ul style="text-align: left">
        <li v-for="panelRef in panelRefs" v-bind:key="panelRef" style="margin: 6px 2px">
          <a class="fakeLink" @click="scrollTo(panelRef)">{{ panelRef.header }}</a>
        </li>
      </ul>
    </div>
    <div
      class="align-items-start justify-content-start"
      style="width: 100%; max-width: 1000px"
    >
      <Panel
        v-if="loggedIn"
        ref="panel1"
        header="Ich finde hier keine passende Antwort oder ausreichende Informationen! Was kann ich tun?"
      >
        <div class="flex flex-column align-items-center justify-content-center">
          <div class="flex align-items-center justify-content-center">
            <h3>Hinterlasse hier eine Nachricht und wir kontaktieren dich!</h3>
          </div>
          <div
            class="flex align-items-center justify-content-center"
            style="margin-top: 10px"
          >
            <div class="p-float-label">
              <Dropdown
                v-model="messageRecipient"
                :options="['Spielleiter', 'Webmaster', 'Programmierer']"
                style="width: 300px"
              />
              <label style="font-size: 14px; text-decoration: underline"
                >Empfänger der Nachricht</label
              >
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center"
            style="margin-top: 25px"
          >
            <div class="p-float-label">
              <Dropdown
                v-model="messageType"
                :options="['Frage', 'Anregung', 'Beschwerde / Fehler']"
                style="width: 300px"
              />
              <label style="font-size: 14px; text-decoration: underline"
                >Art der Nachricht</label
              >
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center"
            style="margin-top: 25px; width: 100%"
          >
            <div class="p-float-label" style="width: 100%">
              <Textarea
                v-model="message"
                :autoResize="true"
                rows="6"
                style="width: 100%"
              />
              <label style="font-size: 14px; text-decoration: underline"
                >Nachrichtentext</label
              >
            </div>
          </div>
          <div class="flex align-items-center justify-content-center">
            <Message
              v-if="hint && hint.length > 0"
              :severity="hintType"
              :closable="true"
              >{{ hint }}</Message
            >
          </div>
          <div
            class="flex align-items-center justify-content-center"
            style="margin-top: 5px"
          >
            <Button label="Abschicken" @click="sendMessage" />
          </div>
        </div>
      </Panel>
      <Panel ref="panel2" header="Wie ist das Programm grundsätzlich aufgebaut?">
        <div class="flex flex-column align-items-center justify-content-center">
          <img src="./images/menu_struktur.png" style="width: 100%" />

          <h2>Hauptmenü</h2>
          <table>
            <tr style="text-align: left">
              <td>
                <i class="pi pi-fw pi-bars" style="font-size: 38px; margin: 10px"></i>
              </td>
              <td width="110px">Seitenmenü</td>
              <td>
                Öffnet das Seitenmenü, welches Funktionen enthält, die nicht regelmäßig
                benötigt werden.
              </td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-cog" style="font-size: 38px"></i></td>
              <td>Einstellungen</td>
              <td>
                Öffnet die Seite für Benutzereinstellungen, aktuell einzige Unterpunkt ist
                die Anpassung der Zugangsdaten (Benutzername, Passwort)
              </td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-question-circle" style="font-size: 38px"></i></td>
              <td>Hilfe</td>
              <td>Öffnet diese Hilfeseite</td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-sign-out" style="font-size: 38px"></i></td>
              <td>Abmelden</td>
              <td>Meldet den aktuellen Benutzer ab und leitet auf die Anmeldeseite</td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-pencil" style="font-size: 38px"></i></td>
              <td>Bearbeiten</td>
              <td>
                Wechselt in den Bearbeitungsmodus um den angezeigten Datensatz zu
                bearbeiten.
              </td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-save" style="font-size: 38px"></i></td>
              <td>Speichern</td>
              <td>
                Speichert die getätigten Änderungen an einem Datensatz. Nur im
                Bearbeitungsmodus vorhanden
              </td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-times" style="font-size: 38px"></i></td>
              <td>Abbrechen</td>
              <td>
                Verwirft die getätigten Änderungen an einem Datensatz. Nur im
                Bearbeitungsmodus vorhanden
              </td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-arrow-left" style="font-size: 38px"></i></td>
              <td>Zurück</td>
              <td>Rückkehr zur vorhergehenden Seite.</td>
            </tr>
            <tr style="text-align: left; margin: 10px">
              <td><i class="pi pi-fw pi-check-square" style="font-size: 38px"></i></td>
              <td>Abschließen</td>
              <td>
                Beenden eines mehrstufigen Bearbeitungsprozess, zum Beispiel der
                Eintragung eines Spielergebnisses
              </td>
            </tr>
          </table>
          <h2>Mannschaft</h2>
          <span
            >Zeigt den Namen der Mannschaften an, für die der angemeldete Benutzer
            Vertretungsrechte besitzt. <br />Hat der Benutzer diese Rechte nur für eine
            Mannschaft, ist diese immer vorausgewählt und kann nicht verändert
            werden.</span
          >
          <h2>Untermenü</h2>
          <table>
            <tr style="text-align: left">
              <td style="border-bottom: 1px solid #ddd">
                <i class="pi pi-fw pi-calendar" style="font-size: 34px; margin: 5px"></i>
              </td>
              <td style="border-bottom: 1px solid #ddd">Spieltage</td>
              <td style="border-bottom: 1px solid #ddd">
                Öffnet die Spieltageübersicht der aktuellen Mannschaft. Hier können Spiel
                eingesehen und bearbeitet werden. Siehe auch:
                <a class="fakeLink" @click="findAndScrollTo('panel3')"
                  >"Wie kann ich ein Spielergebnis eintragen?"</a
                >
              </td>
            </tr>
            <tr style="text-align: left">
              <td style="border-bottom: 1px solid #ddd">
                <i class="pi pi-fw pi-users" style="font-size: 34px; margin: 5px"></i>
              </td>
              <td style="border-bottom: 1px solid #ddd">Mannschaft</td>
              <td style="border-bottom: 1px solid #ddd">
                Öffnet die Spielerübersicht der aktuellen Mannschaft. Hier können Spieler
                umbenannt oder abgemeldet werden. Siehe auch:
                <a class="fakeLink" @click="findAndScrollTo('panel8')"
                  >"Wie kann ich den Namen eines Spielers ändern?"</a
                >
                und
                <a class="fakeLink" @click="findAndScrollTo('panel9')"
                  >"Wie kann ich einen Spieler abmelden?"</a
                >
              </td>
            </tr>
            <tr style="text-align: left">
              <td style="border-bottom: 1px solid #ddd">
                <i class="pi pi-fw pi-user" style="font-size: 34px; margin: 5px"></i>
              </td>
              <td style="border-bottom: 1px solid #ddd">Teamvertreter</td>
              <td style="border-bottom: 1px solid #ddd">
                Öffnet den Datensatz des Teamvertreters/Teamkapitän der aktuellen
                Mannschaft. Sollte für diese Rolle eine Änderung statt gefunden haben,
                kann es dort angepasst werden. Siehe auch:
                <a class="fakeLink" @click="findAndScrollTo('panel11')"
                  >"Wie kann ich den Teamvertreter / Teamkapitän ändern?"</a
                >
              </td>
            </tr>
            <tr style="text-align: left">
              <td style="border-bottom: 1px solid #ddd">
                <i class="pi pi-fw pi-compass" style="font-size: 34px; margin: 5px"></i>
              </td>
              <td style="border-bottom: 1px solid #ddd">Spielort</td>
              <td style="border-bottom: 1px solid #ddd">
                Öffnet den Datensatz des Spielortes der aktuellen Mannschaft. Hier können
                Details zu diesem eingesehen und geändert werden. Hinweis: Aktuell ist es
                nicht möglich, die Anschrift des Spielortes (Straße, PLZ und Ort) zu
                ändern! Bei Änderungen dort oder einem Spielortwechsel bitte den
                Spielleiter informieren. Siehe auch:
                <a class="fakeLink" @click="findAndScrollTo('panel12')"
                  >"Wie kann ich den Spielort anpassen?"</a
                >
              </td>
            </tr>
          </table>
        </div>
      </Panel>
      <Panel ref="panel3" header="Wie kann ich ein Spielergebnis eintragen?">
        <div class="flex flex-column align-items-center justify-content-center">
          <ol style="text-align: left">
            <li style="vertical-align: middle">
              <h3 style="text-decoration: underline">
                Navigation zur Spieltagsübersicht über den Untermenüpunkt
                <i
                  class="pi pi-fw pi-calendar"
                  style="font-size: 28px; vertical-align: middle"
                ></i>
              </h3>
            </li>

            <li>
              <h3 style="text-decoration: underline">
                Auswahl des Spieltags des einzutragenden Spiels mit Hilfe der
                Spieltagsauswahl<br />
              </h3>
              <Paginator
                v-model:first="alibiIndex"
                :rows="1"
                :pageLinkSize="22"
                template="PageLinks"
                :totalRecords="22"
              >
              </Paginator>
            </li>
            <li>
              <h3 style="text-decoration: underline">
                Auswahl des Spiels durch Klick auf den Bearbeiten-Button
                <Button
                  style="margin: 2px 2px; vertical-align: middle"
                  icon="pi pi-pencil"
                  v-tooltip.top="'Editieren'"
                  class="p-button-rounded p-button-warning mb2"
                />
              </h3>
              <Message
                severity="warn"
                :closable="false"
                style="margin: 0px; margin-bottom: 10px"
              >
                Achtung: Es können nur Heimspiele bearbeitet werden, die sich noch nicht
                im Status "Gespielt" befinden und deren Spieldatum dem heutigen Datum
                entspricht oder deren Spieldatum in der Vergangenheit liegt. Weitere
                Informationen unter
                <a class="fakeLink" @click="findAndScrollTo('panel13')"
                  >"Wie ist eigentlich der genau Ablauf einer Spielverarbeitung?"</a
                >
              </Message>
            </li>
            <li>
              <h3 style="text-decoration: underline">
                Eingabe der Ergebnisse des Spiels
              </h3>

              <img src="./images/spielerfassung.png" style="width: 100%" />
              <Message severity="info" :closable="false" style="margin: 0px">
                Die eingetragenen Daten werden sofort gespeichert, es ist keine weitere
                Benutzeraktion hierzu erforderlich.
              </Message>
            </li>
            <li>
              <h3 style="text-decoration: underline">
                Hochladen des Fotos vom Spielbericht, siehe
                <a class="fakeLink" @click="findAndScrollTo('panel4')"
                  >"Wie kann ich ein Bild des Spielergebnisses hochladen?"</a
                >
              </h3>
            </li>
            <li>
              <h3 style="text-decoration: underline">
                Eintragung des Spiels abschließen
              </h3>
              Wenn alle nötigen Informationen im Spielergebnis hinterlegt sind und ein
              Bild hochgeladen wurde, kann die Eintragung durch Klick auf die Schaltfläche
              "Abschließen"
              <i
                class="pi pi-fw pi-check-square"
                style="font-size: 38px; vertical-align: middle"
              ></i>
              im Hauptmenü beendet werden. Vorher ist diese Schaltfläche nicht sichtbar.
              Nach dem Klick auf diese ändert sich der Status des Spiels auf "In
              Bestätigung durch Gast"
              <Message severity="warn" :closable="false" style="margin: 10px 0px">
                Danach ist sind keine Änderungen mehr an den Ergebnissen oder dem Bild
                möglich. Falls danach Änderungen nachgetragen werden sollen, kann dies nur
                über den Spielleiter erfolgen. Siehe auch:
                <a class="fakeLink" @click="findAndScrollTo('panel5')"
                  >"Wie kann ich dem Spielleiter einen Hinweis zu einem unserer Spiele
                  hinterlassen?"</a
                >
              </Message>
            </li>
          </ol>
        </div>
      </Panel>
      <Panel ref="panel4" header="Wie kann ich ein Bild des Spielergebnisses hochladen?">
        <div class="flex flex-column align-items-center justify-content-center">
          <ol style="text-align: left">
            <li>
              <h3>Im Bearbeitungsmodus des Spiels auf den Reiter Bild klicken</h3>
            </li>
            <li>
              <h3>
                Dort auf
                <Button
                  icon="pi pi-plus"
                  label="Bild auswählen und hochladen"
                  style="vertical-align: middle"
                />
                bzw.
                <Button
                  icon="pi pi-plus"
                  label="Anderes Bild auswählen und hochladen"
                  style="vertical-align: middle"
                />
                klicken.
              </h3>
            </li>
            <li>
              <h3>
                Im erscheinenden Dateiauswahl-Dialog das entsprechende Bild auswählen und
                bestätigen. Das Bild wird dann hochgeladen.
              </h3>
              <Message severity="warn" :closable="false" style="margin: 10px 0px">
                Hinweis: Es sind nur noch Bildformate, wie JPG, JPEG, PNG, usw. erlaubt.
                PDFs und andere Dokumente werden abgelehnt.
              </Message>
            </li>
            <li>
              <h3>
                Das Bild kann über die beiden Schaltflächen
                <Button
                  v-tooltip.bottom="'Bild gegen den Uhrzeigersinn drehen'"
                  icon="pi pi-replay"
                  style="margin: 5px 3px; vertical-align: middle"
                />
                <Button
                  v-tooltip.bottom="'Bild im Uhrzeigersinn drehen'"
                  icon="pi pi-refresh"
                  style="margin: 5px 3px; vertical-align: middle"
                />
                gegen bzw. im Uhrzeigersinn gedreht werden.
                <Message severity="info" :closable="false" style="margin: 0px">
                  Die Änderungen an dem Bild sofort gespeichert, es ist keine weitere
                  Benutzeraktion hierzu erforderlich.
                </Message>
              </h3>
            </li>
          </ol>
        </div>
      </Panel>
      <Panel
        ref="panel5"
        header="Wie kann ich dem Spielleiter einen Hinweis zu einem unserer Spiele hinterlassen?"
      >
        <div class="flex flex-column align-items-center justify-content-center">
          <ul style="text-align: left">
            <li>
              In der Detailsansicht des Spiels auf den Reiter Bemerkungen und danach auf
              <Button label="Bemerkung hinzufügen" style="vertical-align: middle" />
              klicken.<br />
              Danach kann im freien Bereich des Dialogs ein Text eingeben werden und
              dieser mit einem Klick auf
              <Button label="Eintragen" style="vertical-align: middle" />
              gespeichert oder mit einem Klick auf
              <Button label="Abbrechen" style="vertical-align: middle" />
              verworfen werden.
              <Message severity="warn" :closable="false" style="margin: 0px">
                Ein nachträgliches Editieren der Bemerkung ist nicht möglich.
              </Message>
            </li>
          </ul>
        </div>
      </Panel>
      <Panel ref="panel6" header="Was kann ich als Gast nach einem Spiel tun?">
        <ul style="text-align: left">
          Der Teamvertreter/Kapitän hat nach der erfolgten Eingabe durch die
          Heim-Mannschaft die Möglichkeit, den Spielbericht zu verifizieren und diesen
          dann mit einem Klick auf
          <i
            class="pi pi-fw pi-check-square"
            style="font-size: 38px; margin: 5px; vertical-align: middle"
          />
          zu bestätigen oder mit einem Klick auf
          <i
            class="pi pi-fw pi-exclamation-triangle"
            style="font-size: 38px; margin: 5px; vertical-align: middle"
          />
          dem Spielleiter einen Hinweis über fehlende oder fehlerhafte Eintragungen im
          Spielbericht hinterlegen. Der Hinweis wird dann in den Bemerkungen hinterlegt
          und der Spielstatus wird auf "In Kontrolle durch Spielleiter" gesetzt. Der
          Spielleiter wird darauf hin automatisch informiert, d.h. es ist keine weitere
          Benachrichtigung nötig.
        </ul>
      </Panel>

      <Panel ref="panel7" header="Warum kann ich manche Spiele nicht (mehr) editieren?">
        <h4>Dies kann mehrere Ursachen haben:</h4>
        <ul style="text-align: left">
          <li>Nur die Heim-Mannschaft kann ein Spielergebnis eintragen</li>
          <li>Spiele können nur an oder nach dem Spieltermin eingetragen werden</li>
          <li>
            Nach dem Abschluss des Spielberichts ist eine nachträglich editieren nicht
            mehr möglich.
          </li>
        </ul>
      </Panel>

      <Panel
        ref="panel8"
        header="Wie kann ich einen neuen Spieler zu meiner Mannschaft hinzufügen?"
      >
        <div>
          <ul style="text-align: left">
            <li>
              Im Menüpunkt Mannschaft
              <i
                class="pi pi-fw pi-users"
                style="font-size: 38px; vertical-align: middle"
              />
              kann über das Seitenmenü
              <i
                class="pi pi-fw pi-bars"
                style="font-size: 38px; vertical-align: middle"
              />
              und den dortigen Menüpunkt "Spieler zur Mannschaft hinzufügen" ein Spieler
              aus dem Verein, der noch keiner Mannschaft zugewiesen ist, hinzugefügt
              werden.
              <Message severity="info" :closable="false" style="margin: 10px 0px">
                Das Programm importiert jede Stunde die aktuelle Liste der Spieler, die
                beim BWDV gemeldet sind und ordnet sie dem dort eingetragenen Verein zu.
              </Message>
              <Message severity="warn" :closable="false" style="margin: 0px">
                Wenn der Spieler in der BWDV-Liste noch nicht enthalten ist, erscheint er
                auch nicht in der Auswahl für neue Spieler. Von der Anmeldung an den BWDV
                bis zur Eintragung in die Liste dauert es normalerweise einige Tage.
              </Message>
            </li>
          </ul>
        </div>
      </Panel>
      <Panel ref="panel9" header="Wie kann ich den Namen eines Spielers ändern?">
        <div>
          <ul style="text-align: left">
            <li>
              Im Menüpunkt Mannschaft
              <i
                class="pi pi-fw pi-users"
                style="font-size: 38px; vertical-align: middle"
              />
              kann durch den Klick auf
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                icon="pi pi-pencil"
                v-tooltip.top="'Editieren'"
                class="p-button-rounded p-button-success mb2"
              />
              der entsprechende Spielername editiert werden. Danach kann die Änderung mit
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                icon="pi pi-save"
                v-tooltip.top="'Speichern'"
                class="p-button-rounded p-button-success mb2"
              />

              gespeichert oder mit
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                icon="pi pi-times"
                v-tooltip.top="'Abbrechen'"
                class="p-button-rounded p-button-warning mb2"
              />
              verworfen werden.
            </li>
          </ul>
        </div>
      </Panel>
      <Panel ref="panel10" header="Wie kann ich einen Spieler abmelden?">
        <div>
          <ul style="text-align: left">
            <li>
              Im Menüpunkt Mannschaft
              <i
                class="pi pi-fw pi-users"
                style="font-size: 38px; vertical-align: middle"
              />
              kann durch den Klick auf
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                icon="pi pi-trash"
                v-tooltip.top="'Abmelden'"
                class="p-button-rounded p-button-warning"
              />
              der Spieler abgemeldet werden.<br />
              Wird dies vor der Saison oder vor der Rückrunde durchgeführt, kann der
              Spieler bei einer anderen Mannschaft des Vereins angemeldet werden.
              <Message severity="warn" :closable="false" style="margin: 10px 0px">
                Diese Abmeldung ist keine Abmeldung beim BWDV. Dies muss nach wie vor über
                das entsprechende Formular geschehen.
              </Message>
            </li>
          </ul>
        </div>
      </Panel>
      <Panel ref="panel11" header="Wie kann ich meine Zugangsdaten ändern?">
        <div>
          <ul style="text-align: left">
            <li>
              Im Hauptmenu auf den Menüpunkt Einstellungen
              <i
                class="pi pi-fw pi-cog"
                style="font-size: 38px; vertical-align: middle"
              />
              klicken. <br />Dort können dann der Benutzername und das Passwort angepasst
              und mit dem Klick auf
              <Button label="Ändern" class="mt-2" style="vertical-align: middle" />
              gespeichert werden. Nach der Bestätigung der Abfragedialogs mit Ja muss eine
              erneute Anmeldung mit den neuen Zugangsdaten durchgeführt werden.
            </li>
          </ul>
        </div>
      </Panel>
      <Panel ref="panel12" header="Wie funktioniert die Spielverlegung?">
        <div class="flex flex-column align-items-center justify-content-center">
          Die Spielverlegung läuft in folgenden Schritten ab:
          <ol style="text-align: left">
            <li>
              Erstellen und Versenden einer Verlegungsanfrage an den Vertreter der
              gegnerischen Mannschaft
            </li>
            <li>
              Beantwortung der Anfrage durch den Vertreter der gegnerischen Mannschaft.
              <ul>
                <li>Falls Datum passend ist, wird das Spiel automatisch verlegt</li>
                <li>
                  Falls kein Datum passend ist, bitte mögliche Alternativ-Termine in das
                  Antwort-Textfeld eintragen, damit eine neue Anfrage mit der Alternative
                  gestellt werden kann.
                </li>
              </ul>
            </li>
          </ol>
          <Message
            severity="warn"
            :closable="false"
            style="margin: 0px; margin-bottom: 10px"
          >
            Das Programm identifiziert die Mannschaft, die das Spiel verlegen möchte,
            anhand der Anfrage. Die angefragte Mannschaft sollte also keine Gegenanfrage
            stellen, sondern bei unpassenden Vorschlägen per Antworttext mögliche
            Alternativtermine anbieten.
          </Message>
          <Message
            severity="info"
            :closable="false"
            style="margin: 0px; margin-bottom: 10px"
          >
            Falls keine Einigung erzielt wird, entscheidet die Spielleitung, die die
            Anfragen und Antworten einsehen kann
          </Message>
          <h3>Wie erstelle ich eine Spielverlegungsanfrage?</h3>
          <ol style="text-align: left">
            <li style="vertical-align: middle">
              <h4 style="text-decoration: underline">
                Navigation zur Spieltagsübersicht über den Untermenüpunkt
                <i
                  class="pi pi-fw pi-calendar"
                  style="font-size: 28px; vertical-align: middle"
                ></i>
              </h4>
            </li>

            <li>
              <h4 style="text-decoration: underline">
                Auswahl des Spieltags des einzutragenden Spiels mit Hilfe der
                Spieltagsauswahl<br />
              </h4>
              <Paginator
                v-model:first="alibiIndex"
                :rows="1"
                :pageLinkSize="22"
                template="PageLinks"
                :totalRecords="22"
              >
              </Paginator>
            </li>
            <li>
              <h4 style="text-decoration: underline">
                Auswahl des Spiels durch Klick auf den Verlegen-Button
                <Button
                  style="margin: 2px 2px; vertical-align: middle"
                  icon="pi pi-calendar-times"
                  v-tooltip.top="'Verlegen'"
                  class="p-button-rounded p-button-warning mb2"
                />
              </h4>
              <Message
                severity="warn"
                :closable="false"
                style="margin: 0px; margin-bottom: 10px"
              >
                Achtung: Es können nur Spiele verlegt werden, deren Spieldatum vor dem
                heutigen Datum liegen.
              </Message>
            </li>
            <li>
              <h4 style="text-decoration: underline">Anfrage erstellen</h4>
              <ol>
                <li>
                  <h4>Mögliche Datumswerte auswählen</h4>
                  Mit Klick auf die blaue Schaltfläche rechts neben dem Datumsfeld
                  <Calendar
                    showIcon
                    :manualInput="true"
                    :showOnFocus="false"
                    v-model="currentDate"
                    dateFormat="dd.mm.yy"
                    showTime
                    hourFormat="24"
                    :hideOnDateTimeSelect="true"
                    variant="filled"
                    style="margin: 2px 2px; vertical-align: middle"
                  />
                  öffnet sich ein Kalender, mit dessen Hilfe ein Alternativdatum sowie
                  eine Uhrzeit ausgewählt werden kann. Der Kalender schließt sich
                  automatisch mit der Auswahl eines Datums, daher empfiehlt es sich zuerst
                  die Uhrzeit einzustellen, sollte diese nicht dem Standardwert (20 Uhr)
                  enstprechen. Das direkte Editieren des Datums im Textfeld ist ebenfalls
                  möglich.
                </li>
                <li>
                  <h4>Übernahme des Datums in die Vorschlagsliste</h4>
                  Das Datum und die Uhrzeit wird erst mit dem Klick auf
                  <Button
                    style="margin: 2px 2px; vertical-align: middle"
                    label="Datum übernehmen"
                    class="p-button-rounded p-button-warn mb2"
                  />
                  in die darunter liegende Liste an Vorschlägen übernommen.
                </li>
                <li>
                  <h4>Entfernen eines Datums aus der Liste</h4>
                  In der Liste kann ein Datum per Klick auf
                  <Button
                    style="margin: 2px 2px; vertical-align: middle"
                    icon="pi pi-times"
                    v-tooltip.top="'Datum wieder entfernen'"
                    class="p-button-rounded p-button-warn"
                  />
                  rechts neben dem Datum wieder entfernt werden.
                </li>
                <li>
                  <h4>Eingabe einer Begründung</h4>
                  Um dem gegnerischen Mannschaftsvertreter und der Spielleitung eine
                  Begründung für die Spielverlegung nennen zu können, kann und sollte das
                  darunter liegende Textfeld verwendet werden.
                </li>
                <li>
                  <h4>Abschließen der Verlegungsanfrage</h4>
                  Wenn alle alternativen Datumswerte in der Liste eingetragen wurden und
                  die Begründung formuliert ist, kann die Anfrage per Klick auf
                  <Button
                    label="Abschließen"
                    icon="pi pi-check"
                    icon-pos="right"
                    class="p-button-success"
                    style="margin: 2px 2px; vertical-align: middle"
                  />
                  abgeschlossen werden.
                  <Message
                    severity="warn"
                    :closable="false"
                    style="margin: 0px; margin-bottom: 10px"
                  >
                    Achtung: Die Anfrage kann nur abgeschlossen werden, wenn mindestens
                    ein Datum in der Liste der "Vorschlagene Datumswerte" vorhanden ist.
                  </Message>
                </li>
              </ol>
            </li>
          </ol>
          <h3>Wie beantworte ich eine Spielverlegungsanfrage?</h3>
          Das potentiell eine Anfrage vorliegt ist an der kleinen Zahl rechts über dem
          Nachrichtenmenüpunkt ersichtlich.
          <tab-menu
            :model="teamAdminMenuItems"
            :activeIndex="0"
            style="margin-top: 2px; display: flex; justify-content: center"
          >
            <template #item="{ item }">
              <a
                class="p-menuitem-link"
                style="display: flex; flex-direction: column; padding: 5px 6px"
              >
                <span
                  v-badge="1"
                  :class="item.icon + ' p-overlay-badge'"
                  style="font-size: 36px"
                ></span>
                <span class="p-menuitem-text" style="margin-top: 8px; font-size: 12px">{{
                  item.label
                }}</span>
              </a>
            </template>
          </tab-menu>
          <ol style="text-align: left">
            <li>
              Mit Klick auf den Menüpunkt Nachrichten öffnet sich die Nachrichtenübersicht
            </li>
            <li>
              Dort auf die entsprechende Anfrage mit Klick auf
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                icon="pi pi-search"
                v-tooltip.top="'Nachricht öffnen'"
                class="p-button-rounded p-button-warn mb2"
              />
              die Nachricht öffnen.
            </li>
            <li>
              In der Nachricht kann dann auf ein passendes Datum <br />
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                label="29.02.2035 20:00 Uhr"
              />
              oder, falls kein Termin passt, auf <br />
              <Button
                style="margin: 2px 2px; vertical-align: middle"
                label="Kein Termin ist passend"
              />
              geklickt werden. <br />
              Bei letzterem im Anschluß in das nun geöffnete Fenster eine Begründung und
              idealerweise Termin-Gegenvorschläge eintragen.
            </li>
          </ol>
        </div>
      </Panel>
      <Panel ref="panel13" header="Wie funktioniert der Heimrechttausch?">
        <div class="flex flex-column align-items-center justify-content-center">
          <h4>
            Der Heimrechttausch funktioniert genau wie die Spielverlegung (
            <a class="fakeLink" @click="findAndScrollTo('panel12')"
              >Wie funktioniert die Spielverlegung?</a
            >) mit zwei Ausnahmen:
            <ol style="text-align: left">
              <ul>
                Die Klickfläche neben dem Spiel sieht so aus:
                <Button
                  style="margin: 2px 2px; vertical-align: middle"
                  icon="pi pi-code"
                  v-tooltip.top="'Heimrecht tauschen'"
                  class="p-button-rounded p-button-warning mb2"
                />
              </ul>
              <ul>
                Statt Datumswerten muss ausgewählt werden, ob der Tausch nur für dieses
                Spiel gilt oder ob das Heimrecht mit dem Rückspiel getauscht werden soll.
              </ul>
            </ol>
          </h4>
        </div>
      </Panel>
      <Panel
        ref="panel14"
        header="Wie kann ich meinen 2K Vereinsaccount mit dem BDL-Programm verbinden"
      >
        <div class="flex flex-column align-items-center justify-content-center">
          <h4>
            <ol style="text-align: left">
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block"
                  >Im Menü auf den Bereich "Weiteres", dann auf 2K-Einstellungen
                  klicken.</span
                >
                <img src="./images/2keinstellungen.png" style="width: 200px" />
              </ul>
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block">
                  Nun muss der 2K-Mandanten-Key aus dem Bereich Kontoübersicht in der
                  2k-Verwaltung in das entsprechende Feld im BDL-Programm eingetragen
                  werden.
                </span>
                <img src="./images/2kaccountoverview.png" style="width: 400px" />
                <img src="./images/2keinstellungen_2.png" style="width: 400px" />
              </ul>
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block">
                  Nach dem Klick auf den "Einstellungen übernehmen"-Button erscheint ein
                  Hinweis-Dialog ob die Verbindung funktioniert hat.
                </span>
                <img src="./images/2keinstellungen_valid.png" style="width: 400px" />
                <span style="display: block">
                  Falls dies nicht erfolgreich war, überprüfen Sie bitte den
                  2K-Mandanten-Key, stellen sicher, dass Spiele für Ihren Verein in 2K
                  eingetragen sind und wenden sich, bei Bedarf, an den Programmierer des
                  BDL-Prgramms.
                </span>
                <img src="./images/2keinstellungen_invalid.png" style="width: 400px" />
              </ul>
            </ol>
          </h4>
        </div>
      </Panel>
      <Panel
        ref="panel15"
        header="Wie kann ich mein Spielergebnis von 2K in das BDL-Programm importieren"
      >
        <div class="flex flex-column align-items-center justify-content-center">
          <h4>
            <ol style="text-align: left">
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block"
                  >Öffnen Sie das Spiel in der Spieltagsübersicht mit
                  <Button
                    style="margin: 2px 2px; vertical-align: middle"
                    icon="pi pi-pencil"
                    v-tooltip.top="'Editieren'"
                    class="p-button-rounded p-button-warning mb2"
                  />
                  oder
                  <Button
                    style="margin: 2px 2px; vertical-align: middle"
                    icon="pi pi-eye"
                    v-tooltip.top="'Details'"
                    class="p-button-rounded p-button-success mb2"
                  />
                  und klicken Sie anschließend auf den Reiter 2K-Import.
                </span>
                <img src="./images/2kimportmenu.png" style="width: 200px" />
                <Message
                  severity="warn"
                  :closable="false"
                  style="margin: 0px; margin-bottom: 10px"
                >
                  Der Import kann nur durchgeführt werden, wenn der 2K-Mandanten-Key
                  eingetragen ist und das Spiel nicht bereits abgeschlossen worden ist.
                  Sonst ist der 2K-Import-Reiter nicht sichtbar.
                </Message>
              </ul>
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block">
                  Die Spiele aus 2K werden geladen und das passende Spiel kann
                  anschließend über das Auswahlfeld gewählt werden. Die Spiele sind nach
                  Datum sortiert, das passende Spiel sollte also weit oben zu finden sein.
                </span>
                <img src="./images/2kimportchoose.png" style="width: 400px" />
              </ul>
              <Message
                severity="warn"
                :closable="false"
                style="margin: 0px; margin-bottom: 10px"
              >
                Achtung: Es werden nur Spiele in der Auswahl angezeigt, die nicht bereits
                archiviert sind und die einen eingetragenen Spielplan haben.
              </Message>
              <ul class="flex flex-column align-items-center justify-content-center">
                <span style="display: block">
                  Nach der Auswahl kann der Import über den Button
                  <Button
                    label="Manuellen Import starten"
                    style="margin-bottom: 4px; width: 300px; vertical-align: middle"
                  />
                  gestartet werden.
                </span>

                <span style="display: block">
                  Nach dem Import springt das Programm zur Spielübersicht und der Import
                  kann überprüft werden. Im 2K-Bereich des Spiels ist außerdem eim
                  Protokoll des letzten Import für 14 Tage hinterlegt.
                </span>
              </ul>
            </ol>
          </h4>
          <Message
            severity="info"
            :closable="false"
            style="margin: 0px; margin-bottom: 10px"
          >
            Der Import kann beliebig oft ausgeführt werden, auch während des Spiels.
          </Message>
        </div>
      </Panel>
      <!--
      <Panel
        ref="panel11"
        header="Wie kann ich den Teamvertreter / Teamkapitän ändern?"
      ></Panel>
      <Panel ref="panel12" header="Wie kann ich den Spielort anpassen?"> </Panel>
      <Panel
        ref="panel13"
        header="Wie ist eigentlich der genaue Ablauf einer Spielverarbeitung?"
      >
      </Panel>
      -->
    </div>
    <ScrollTop :threshold="200" />
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Panel from "primevue/panel";
import ScrollTop from "primevue/scrolltop";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import Message from "primevue/message";
import Paginator from "primevue/paginator";
import Calendar from "primevue/calendar";
import TabMenu from "primevue/tabmenu";
export default {
  name: "HelpMain",
  components: {
    Panel,
    ScrollTop,
    Dropdown,
    Textarea,
    Button,
    Message,
    Paginator,
    Calendar,
    TabMenu,
  },
  created() {
    this.updateMainMenu();
    this.updatePanelRefs();
  },
  updated() {
    this.updateMainMenu();
    this.updatePanelRefs();
  },
  mounted() {
    this.updatePanelRefs();
  },
  computed: {
    loggedIn() {
      var user = localStorage.getItem("user");
      return user && user != null;
    },
    currentDate() {
      var date = new Date();
      date.setHours(20);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },
    teamAdminMenuItems() {
      return [
        {
          index: 0,
          name: "nachrichten",
          label: "Nachrichten",
          icon: "pi pi-fw pi-bell",
          badged: true,
          style: "padding: 0px 1px",
        },
      ];
    },
  },
  data() {
    return {
      panelRefs: [],
      hint: "",
      hintType: "",
      messageRecipient: "",
      messageType: "",
      message: "",
      alibiIndex: 10,
    };
  },
  props: {},
  methods: {
    updateMainMenu() {
      var menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames([
        {
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        },
      ]);
    },
    updatePanelRefs() {
      if (this.$refs) {
        this.panelRefs = this.$refs;
        if (!this.loggedIn) {
          delete this.panelRefs.panel1;
        }
      }
    },
    scrollTo(ref) {
      if (ref && ref.$el) {
        ref.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
    findAndScrollTo(refName) {
      var foundRef = this.panelRefs[refName];
      if (foundRef) {
        this.scrollTo(foundRef);
      }
    },
    sendMessage() {
      if (!this.messageRecipient || this.messageRecipient.length < 1) {
        this.hintType = "error";
        this.hint = "Bitte den Empfänger der Nachricht angeben!";
        return;
      }
      if (!this.messageType || this.messageType.length < 1) {
        this.hintType = "error";
        this.hint = "Bitte die Art der Nachricht angeben!";
        return;
      }
      if (!this.message || this.message.length < 1) {
        this.hintType = "error";
        this.hint = "Bitte einen Nachrichtentext angeben!";
        return;
      }
      var serverMessageType = "UNKNOWN";
      switch (this.messageType) {
        case "Frage":
          serverMessageType = "QUESTION";
          break;
        case "Anregung":
          serverMessageType = "SUGGESTION";
          break;
        case "Beschwerde / Fehler":
          serverMessageType = "ERROR";
          break;
      }
      ServerConnection.put(
        "teamadmin/message/",
        JSON.stringify({
          message: this.message,
          type: serverMessageType,
          recipientString: this.messageRecipient,
        })
      )
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .then(() => {
          this.hintType = "info";
          this.hint =
            "Die Nachricht wurde versendet. Wir setzen uns schnellstmöglich mit dir in Verbindung.";
          this.messageRecipient = "";
          this.messageType = "";
          this.message = "";
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
  },
};
</script>

<style lang="css">
.fakeLink {
  cursor: pointer;
  text-decoration: underline;
}
.p-overlay-badge .p-badge {
  transform: translate(20%, -20%) !important;
}
</style>
