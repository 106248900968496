<template>
  <div class="flex align-items-center justify-content-center">
    <tab-menu
      v-if="teamAdminMenuItems && teamAdminMenuItems.length > 0"
      :model="teamAdminMenuItems"
      :activeIndex="activeMenuItem ? activeMenuItem.index : -1"
      style="margin-top: 10px; min-width: 200px; display: flex; justify-content: center"
    >
      <template #item="{ item }">
        <a
          class="p-menuitem-link"
          @click="onItemClick(item)"
          style="display: flex; flex-direction: column; padding: 5px 10px"
        >
          <span :class="item.icon" style="font-size: 38px" v-if="item.icon"></span>
          <span class="p-menuitem-text" style="margin-top: 8px; font-size: 12 px">{{
            item.label
          }}</span>
        </a>
      </template>
    </tab-menu>
  </div>
  <div>
    <div
      class="flex flex-wrap align-items-center justify-content-center"
      style="margin-top: 20px; width: 100%"
    >
      <div v-if="activeMenuItem && activeMenuItem.name == 'login' && user">
        <div class="card">
          <div class="field grid" style="align-items: baseline">
            <label for="name" class="col-fixed" style="width: 130px">Benutzername</label>
            <InputText id="name" v-model="user.username" />
          </div>
          <div class="field grid">
            <label for="password" class="col-fixed" style="width: 130px">Passwort</label>
            <Password id="password" v-model="user.password" :feedback="false"> </Password>
          </div>
          <Button label="Ändern" class="mt-2" @click="changeLogin()" />
        </div>
        <Dialog v-model:visible="showChangeLogInDialog" :closable="false">
          <template #header>
            <h3>Zugangsdaten ändern</h3>
          </template>
          Sollen ihre Zugangsdaten wirklich geändert werden? <br />
          Sie müssen sich danach neu anmelden.
          <template #footer>
            <Button
              label="Ja"
              icon="pi pi-times"
              class="p-button-text"
              @click="executeChangeLogin()"
            />
            <Button label="Abbrechen" icon="pi pi-check" @click="cancelChangeLogin()" />
          </template>
        </Dialog>
      </div>
      <div
        v-if="activeMenuItem && activeMenuItem.name == 'resultMode' && user"
        style="width: 100%"
      >
        <div class="flex flex-column">
          <div class="flex align-items-center justify-content-center">
            <div class="card" style="width: 100%; max-width: 250px">
              <div
                class="flex flex-column card-container justify-content-center"
                style="
                  padding: 15px;
                  border: 1px solid black;
                  min-width: 100px;
                  max-width: 300px;
                "
              >
                <div
                  class="flex align-items-center justify-content-center"
                  style="margin: 10px; font-size: 20px"
                >
                  {{ resultSlider.pointsHome }} : {{ resultSlider.pointsGuest }}
                </div>
                <div>
                  <Slider
                    :min="0"
                    :max="6"
                    v-model="resultSlider.value"
                    @change="gameResultChangedSlider"
                  />
                </div>
              </div>
            </div>
            <Button
              :label="resultMode == 'slider' ? 'Ausgewählt' : 'Ändern'"
              @click="changeResultMode('slider')"
              style="margin-left: 5px; padding: 5px; font-size: 18px; width: 170px"
            />
          </div>
          <div
            class="flex align-items-center justify-content-center"
            style="margin-top: 10px"
          >
            <div class="card" style="width: 100%; max-width: 250px">
              <div
                class="flex flex-column card-container justify-content-center"
                style="
                  padding: 15px;
                  border: 1px solid black;
                  min-width: 100px;
                  max-width: 300px;
                "
              >
                <div
                  class="flex align-items-center justify-content-center"
                  style="margin: 10px; font-size: 20px"
                >
                  {{ resultButtons.pointsHome }} : {{ resultButtons.pointsGuest }}
                </div>
                <div>
                  Gewinner:
                  <SelectButton
                    :options="['Heim', 'Gast']"
                    v-model="resultButtons.winner"
                    @change="gameResultChangedButtons"
                  />
                </div>
                <div style="margin-top: 15px">
                  Punkte Verlierer:
                  <SelectButton
                    :options="[0, 1, 2]"
                    v-model="resultButtons.pointsOpponent"
                    @change="gameResultChangedButtons"
                  />
                </div>
              </div>
            </div>
            <Button
              :label="resultMode == 'buttons' ? 'Ausgewählt' : 'Ändern'"
              @click="changeResultMode('buttons')"
              style="margin-left: 5px; padding: 5px; font-size: 18px; width: 170px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import { useSettingsService } from "../../services/SettingsService/UseSettingsService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import TabMenu from "primevue/tabmenu";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Slider from "primevue/slider";
import SelectButton from "primevue/selectbutton";
export default {
  name: "Test",
  components: {
    TabMenu,
    InputText,
    Password,
    Button,
    Dialog,
    Slider,
    SelectButton,
  },
  created() {
    this.updateData();
  },
  updated() {
    this.updateData();
  },
  data() {
    return {
      settingService: useSettingsService(),
      teamAdminMenuItems: [
        {
          index: 0,
          name: "login",
          label: "Zugangsdaten",
          icon: "pi pi-fw pi-sign-in",
          style: "padding: 0px 1px",
        },
        {
          index: 1,
          name: "resultMode",
          label: "Ergebniseingabe",
          icon: "pi pi-table",
          style: "padding: 0px 1px",
        },
      ],
      activeMenuItem: null,
      user: null,
      showChangeLogInDialog: false,
      resultMode: "slider",
      resultSlider: {
        value: 2,
        pointsHome: 3,
        pointsGuest: 1,
      },
      resultButtons: {
        winner: "Heim",
        pointsOpponent: 1,
        pointsHome: 3,
        pointsGuest: 1,
      },
    };
  },
  props: {},
  methods: {
    updateData() {
      this.settingService.getSettings().then((settings) => this.updateSettings(settings));
      const menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames([
        {
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        },
      ]);
      if (this.user == null || this.user.userName == "") {
        var loggedInUser = JSON.parse(localStorage.getItem("user"));
        this.user = {
          username: loggedInUser.username,
          password: "DummyPassword",
        };
      }
      if (this.activeMenuItem == null) {
        this.activeMenuItem = this.teamAdminMenuItems[0];
      }
    },
    updateSettings(settings) {
      this.resultMode = settings.resultMode ?? "slider";
    },
    changeLogin() {
      this.showChangeLogInDialog = true;
    },
    executeChangeLogin() {
      this.showChangeLogInDialog = false;
      var changeLoginRequest = {
        newUsername: this.user.username,
        newPassword: this.user.password != "DummyPassword" ? this.user.password : "",
      };
      ServerConnection.put("changeLogin", changeLoginRequest)
        .then(() => {
          this.$store.dispatch("auth/logout").then(() => this.$router.push("/login"));
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    cancelChangeLogin() {
      this.showChangeLogInDialog = false;
      this.user = null;
      this.updateData();
    },

    onItemClick(item) {
      this.activeMenuItem = item;
    },
    gameResultChangedSlider(value) {
      var v = value - 3;
      if (v == 0) {
        this.resultSlider.pointsHome = 0;
        this.resultSlider.pointsGuest = 0;
      } else if (v < 0) {
        this.resultSlider.pointsHome = 3;
        this.resultSlider.pointsGuest = 3 + v;
      } else {
        this.resultSlider.pointsGuest = 3;
        this.resultSlider.pointsHome = 3 - v;
      }
    },
    gameResultChangedButtons() {
      if (this.resultButtons.winner == "Heim") {
        this.resultButtons.pointsHome = 3;
        this.resultButtons.pointsGuest = this.resultButtons.pointsOpponent;
      } else if (this.resultButtons.winner == "Gast") {
        this.resultButtons.pointsGuest = 3;
        this.resultButtons.pointsHome = this.resultButtons.pointsOpponent;
      }
    },
    changeResultMode(resultMode) {
      if (this.resultMode != resultMode) {
        this.settingService
          .saveSetting("resultMode", resultMode)
          .then((settings) => this.updateSettings(settings));
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
