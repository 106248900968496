<template>
  <div
    class="flex align-items-center justify-content-center"
    style="margin-top: 30px; min-width: 180px"
  >
    <div>
      <h3>Verschiebungen pro Liga und Mannschaft nach Hin- und Rückrunde</h3>
      <Accordion style="padding-bottom: 20px">
        <AccordionTab
          v-for="postponedGamePerLeague in postponedGamesPerLeague"
          v-bind:key="postponedGamePerLeague.league"
          :header="
            postponedGamePerLeague.league +
            ' (' +
            (postponedGamePerLeague.firstHalfTeamPostponedGames.length +
              postponedGamePerLeague.secondHalfTeamPostponedGames.length) +
            ')'
          "
        >
          <div>
            <div v-if="postponedGamePerLeague.firstHalfTeamPostponedGames.length > 0">
              <h3 style="margin: 5px">Hinrunde</h3>
              <Accordion style="padding-bottom: 20px">
                <AccordionTab
                  v-for="postponedGamePerTeam in postponedGamePerLeague.firstHalfTeamPostponedGames"
                  v-bind:key="postponedGamePerTeam.teamId"
                  :header="
                    postponedGamePerTeam.team +
                    ' (' +
                    postponedGamePerTeam.postponedGames.length +
                    ')'
                  "
                >
                  <div
                    v-for="postponedGame in postponedGamePerTeam.postponedGames"
                    v-bind:key="postponedGame.id"
                    style="text-align: center"
                  >
                    <div class="flex align-items-center" style="min-height: 30px">
                      <span>{{ postponedGame.gameDisplayname }}</span>
                      <Button
                        style="margin: 2px 10px; height: 30px; width: 30px"
                        icon="pi pi-eye"
                        class="p-button-rounded p-button-success mb2"
                        @click="showGame(postponedGame.id)"
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
            <div v-if="postponedGamePerLeague.secondHalfTeamPostponedGames.length > 0">
              <h3 style="margin: 5px">Rückrunde</h3>
              <Accordion style="padding-bottom: 20px">
                <AccordionTab
                  v-for="postponedGamePerTeam in postponedGamePerLeague.secondHalfTeamPostponedGames"
                  v-bind:key="postponedGamePerTeam.teamId"
                  :header="
                    postponedGamePerTeam.team +
                    ' (' +
                    postponedGamePerTeam.postponedGames.length +
                    ')'
                  "
                >
                  <div
                    v-for="postponedGame in postponedGamePerTeam.postponedGames"
                    v-bind:key="postponedGame.id"
                  >
                    <div class="flex align-items-center" style="min-height: 30px">
                      <span>{{ postponedGame.gameDisplayname }}</span>
                      <Button
                        style="margin: 2px 10px; height: 30px; width: 30px"
                        icon="pi pi-eye"
                        class="p-button-rounded p-button-success"
                        @click="showGame(postponedGame.id)"
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
export default {
  name: "postponedGamePerLeague.league",
  components: {
    Accordion,
    AccordionTab,
    Button,
  },
  created() {
    this.updateMenu();
    this.loadPostponedGames();
  },
  updated() {
    this.updateMenu();
    this.loadPostponedGames();
  },
  data() {
    return {
      postponedGamesPerLeague: null,
    };
  },
  props: {},
  methods: {
    updateMenu() {
      const menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames([
        {
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        },
        "home",
      ]);
    },
    loadPostponedGames() {
      if (this.postponedGamesPerLeague == null) {
        ServerConnection.get("admin/postponedgames")
          .then((resp) => {
            this.postponedGamesPerLeague = resp.data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    showGame(gameId) {
      this.$router.push("/game/view/" + gameId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
