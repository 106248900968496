<template>
  <div class="" style="margin-top: 10px">
    <SelectButton
      style="padding: 10px"
      v-model="currentMannschaft"
      :options="mannschaften"
      @change="currentMannschaftChanged"
      optionLabel="name"
    />
    <div class="flex align-items-center justify-content-center" v-if="currentMannschaft">
      <MegaMenu
        :model="teamAdminMenuItems"
        breakpoint="395px"
        style="margin-top: 10px; min-width: 200px; display: flex; justify-content: center"
      >
        <template #item="{ item }">
          <a
            class="p-menuitem-link"
            @click="onItemClick(item)"
            :style="
              'display: flex; flex-direction: column; padding: 5px 6px;border: solid' +
              (item.active ? ';color: #2196f3' : '#dee2e6') +
              ';border-width: 0 0 3px 0;' +
              (item.active ? ';color: #2196f3' : '')
            "
          >
            <span
              v-if="item.icon && item.badged && getBadgeCount(item)"
              v-badge="getBadgeCount(item)"
              :class="item.icon + ' p-overlay-badge'"
              style="font-size: 34px"
            ></span>
            <span v-else-if="item.icon" :class="item.icon" style="font-size: 34px"></span>
            <img v-else-if="item.img" :src="item.img" style="font-size: 38px" />
            <span
              class="p-menuitem-text"
              :style="
                'margin-top: 8px; font-size: 12px; font-weight: 600;' +
                (item.active ? ';color: #2196f3' : '')
              "
              >{{ item.label }}</span
            >
          </a>
        </template>
      </MegaMenu>
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'spieltage' && currentMannschaft">
      <team-game-day-view
        v-model:mannschaft="currentMannschaft"
        v-model:gameDayViewIndex="gameDayViewIndex"
        @gameDayViewIndexChanged="gameDayViewIndexChanged"
      />
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'mannschaft'">
      <div
        class="flex flex-wrap card-container align-items-center justify-content-center"
        style="margin-top: 10px"
      >
        <Dialog v-model:visible="showSignOffDialog" :closable="false">
          <template #header>
            <h3>Spieler abmelden</h3>
          </template>

          Soll der Spieler {{ signOffPlayer.name }} wirklich abgemeldet werden?

          <template #footer>
            <Button
              label="Abmelden"
              icon="pi pi-times"
              class="p-button-text"
              @click="executeSignOff"
            />
            <Button label="Abbrechen" icon="pi pi-check" @click="cancelEdit" />
          </template>
        </Dialog>
        <div style="max-width: 1000px; width: 100%">
          <DataTable
            class="p-datatable-sm"
            :value="currentMannschaft.players"
            stripedRows
            responsiveLayout="stack"
            breakpoint="550px"
            style="margin-top: 10px"
          >
            <Column field="bwdvNumber" header="BWDV-Nr."></Column>
            <Column field="name" header="Name">
              <template #body="{ data, field }">
                <InputText
                  v-if="editPlayer && data.id == editPlayer.id"
                  v-model="data[field]"
                  autofocus
                />
                <span v-else>{{ data[field] }}</span>
              </template></Column
            >
            <Column field="eligibleToPlaySince" header="Spielberechtigt seit/ab"></Column>
            <Column field="eligibleToPlayTill" header="Spielberechtigt bis"></Column>
            <Column header="Aktionen">
              <template #body="slotProps">
                <!--
                <Button
                  v-if="!editPlayer"
                  style="margin: 2px 2px"
                  icon="pi pi-pencil"
                  v-tooltip.top="'Editieren'"
                  class="p-button-rounded p-button-success mb2"
                  @click="edit(slotProps.data)"
                />-->
                <Button
                  v-if="!editPlayer"
                  style="margin: 2px 2px"
                  icon="pi pi-trash"
                  v-tooltip.top="'Abmelden'"
                  class="p-button-rounded p-button-warning"
                  @click="signOff(slotProps.data)"
                />
                <Button
                  v-if="editPlayer && slotProps.data.id == editPlayer.id"
                  style="margin: 2px 2px"
                  icon="pi pi-save"
                  v-tooltip.top="'Speichern'"
                  class="p-button-rounded p-button-success mb2"
                  @click="save()"
                />
                <Button
                  v-if="editPlayer && slotProps.data.id == editPlayer.id"
                  style="margin: 2px 2px"
                  icon="pi pi-times"
                  v-tooltip.top="'Abbrechen'"
                  class="p-button-rounded p-button-warning mb2"
                  @click="cancelEdit()"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'pokal'">
      <TeamAdminCupView :currentTeamId="currentMannschaft.id" />
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'spielort'">
      <div
        class="flex align-items-center justify-content-center"
        v-if="currentMannschaft"
      >
        <Message severity="info" :closable="true"
          >Hinweis: Die Anschrift des Spielortes (Straße, PLZ und Ort) kann aktuell nur
          der Spielleiter ändern! Bei Änderungen oder Spielortwechsel diesen bitte
          informieren.</Message
        >
      </div>
      <RecordView
        ref="spielortRecordView"
        recordType="location"
        :recordId="currentMannschaft.location.id"
        :showLinks="false"
        :disabledFields="['street', 'plz', 'city']"
      />
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'contactperson'">
      <RecordView
        ref="contactpersonRecordView"
        recordType="contactperson"
        :recordId="currentMannschaft.primaryContact.id"
        :showLinks="false"
      />
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'nachrichten'">
      <div
        class="flex flex-wrap card-container align-items-center justify-content-center"
        style="margin-top: 10px"
      >
        <div style="max-width: 1000px; width: 100%">
          <DataTable
            class="p-datatable-sm"
            :rowClass="conversationRowClass"
            :value="teamConversations"
            stripedRows
            responsiveLayout="stack"
            breakpoint="550px"
            style="margin-top: 10px"
          >
            <Column header="Letzte Änderung">
              <template #body="slotProps">
                {{ formatDateTimeInfo(slotProps.data.updateTimestamp) }}
              </template>
            </Column>
            <Column header="Zuletzt gelesen am">
              <template #body="slotProps">
                {{ formatDateTimeInfo(slotProps.data.readTimestamp) }}
              </template>
            </Column>
            <Column header="Geantwortet">
              <template #body="slotProps">
                {{
                  slotProps.data.resultingAnswer &&
                  slotProps.data.resultingAnswer.length > 0
                    ? "Ja"
                    : "Nein"
                }}
              </template>
            </Column>
            <Column field="subject" header="Betreff"></Column>
            <Column header="Aktionen">
              <template #body="slotProps">
                <Button
                  style="margin: 2px 2px"
                  icon="pi pi-search"
                  v-tooltip.top="'Nachricht öffnen'"
                  class="p-button-rounded p-button-warn mb2"
                  @click="showConversationDialog(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == '2ksettings'">
      <div
        class="flex flex-wrap card-container align-items-center justify-content-center"
        style="margin-top: 20px"
      >
        <div class="card">
          <h4>
            Der Import des Spielberichts von 2K ist aktuell in der Testphase.<br />
            Einrichtung auf eigenes Risiko!
          </h4>
          <div class="field grid" style="align-items: baseline">
            <label for="2KClientId" class="col-fixed" style="width: 150px"
              >2K-Mandanten-Key</label
            ><InputNumber
              id="2KClientId"
              v-model="twoKSettings.clientId"
              inputId="withoutgrouping"
              :useGrouping="false"
            />
          </div>
          <Button
            label="Einstellungen übernehmen"
            class="mt-2"
            @click="change2KSettings()"
          />
          <Dialog v-model:visible="display2KSettingsDialog" :closable="false">
            <template #header>
              <h3>2K Einstellungen ändern</h3>
            </template>
            <div v-if="!twoKSettings.error || twoKSettings.error.length < 1">
              <h4 style="color: green">Die 2K-Einstellungen sind gültig!</h4>
              <h4>Mandanten-ID: {{ twoKSettings.clientId }}</h4>
              <h4>Backend-ID: {{ twoKSettings.backendId }}</h4>
            </div>
            <div v-else>
              <h4 style="color: red">
                Die 2K-Einstellungen sind ungültig oder kein Spiel gefunden
              </h4>
              <span> {{ twoKSettings.error }}</span>
            </div>
            <template #footer>
              <Button
                label="Schließen"
                class="p-button-text"
                @click="display2KSettingsDialog = false"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    id="conversationDialog"
    modal
    v-model:visible="displayConversationDialog"
    style="min-width: 300px; max-width: 600px"
    :closable="true"
  >
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 20px; text-align: center; width: 100%"
      >
        {{ conversation.subject }}
      </div>
    </template>
    <ConversationView
      :withHeader="false"
      :conversation="conversation"
      @answered="closeConversationDialog"
    />
  </Dialog>
</template>

<script>
import twoKLogo from "../../assets/2k-logo.jpg";
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import TeamGameDayView from "./TeamGameDayView";
import ConversationView from "../conversation/ConversationView";
import SelectButton from "primevue/selectbutton";
import MegaMenu from "primevue/megamenu";
import RecordView from "../records/RecordView.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import { useWizardService } from "../../services/WizardService/UseWizardService";
import TeamAdminCupView from "./TeamAdminCupView";
const gameDaysMenuItem = {
  index: 0,
  name: "spieltage",
  label: "Spieltage",
  icon: "pi pi-fw pi-calendar",
  style: "padding: 0px 1px",
  active: false,
  items: [],
};
const teamMenuItem = {
  index: 1,
  name: "mannschaft",
  label: "Mannschaft",
  icon: "pi pi-fw pi-users",
  style: "padding: 0px 1px",
  active: false,
  items: [],
};
const cupMenuItem = {
  index: 2,
  name: "pokal",
  label: "Pokal",
  icon: "pi pi-fw pi-sitemap",
  style: "padding: 0px 1px",
  active: false,
  items: [],
};
const conversationMenuItem = {
  index: 3,
  name: "nachrichten",
  label: "Nachrichten",
  icon: "pi pi-fw pi-bell",
  badged: true,
  style: "padding: 0px 1px",
  active: false,
  items: [],
};
const contactPersonMenuItem = {
  label: "Vertretung",
  index: 0,
  name: "contactperson",
  icon: "pi pi-fw pi-user",
  style: "padding: 0px 1px",
  active: false,
};
const locationMenuItem = {
  index: 1,
  name: "spielort",
  label: "Spielort",
  icon: "pi pi-fw pi-compass",
  style: "padding: 0px 1px",
  active: false,
};
const twoKMenuItem = {
  index: 2,
  name: "2ksettings",
  label: "2K-Einstellungen",
  img: twoKLogo,
  style: "padding: 0px 1px",
  active: false,
};
const etcMenuItem = {
  index: 4,
  name: "weiteres",
  label: "Weiteres",
  icon: "pi pi-fw pi-ellipsis-v",
  style: "padding: 0px 1px",
  active: false,
  items: [
    [
      {
        items: [contactPersonMenuItem, locationMenuItem, twoKMenuItem],
      },
    ],
  ],
};
export default {
  name: "TeamAdminDashBoard",
  components: {
    ConversationView,
    SelectButton,
    MegaMenu,
    RecordView,
    DataTable,
    Column,
    Button,
    InputText,
    InputNumber,
    Dialog,
    Message,
    TeamGameDayView,
    TeamAdminCupView,
  },
  computed: {
    loggedIn() {
      var user = localStorage.getItem("user");
      return user && user != null;
    },
    showSignOffDialog() {
      return this.signOffPlayer && this.signOffPlayer != null;
    },
    teamAdminMenuItems() {
      var menuItems = [gameDaysMenuItem, teamMenuItem];

      if (this.currentTeamPlaysCup()) {
        menuItems.push(cupMenuItem);
      }
      menuItems.push(conversationMenuItem);
      menuItems.push(etcMenuItem);

      var activeMenuItemName;
      if (this.activeMenuItem) {
        activeMenuItemName = this.activeMenuItem.name;
      } else {
        activeMenuItemName = localStorage.getItem("activeMenuItemName");
      }
      this.handleMenuItemAktiveByName(activeMenuItemName, menuItems);
      return menuItems;
    },
  },
  data() {
    return {
      menuService: useMenuService(),
      wizardService: useWizardService(),
      mannschaften: [],
      currentMannschaft: null,
      gameDayViewIndex: -1,
      teamConversations: [],

      activeMenuItem: null,
      editPlayer: null,
      signOffPlayer: null,
      displayConversationDialog: false,
      display2KSettingsDialog: false,
      conversation: null,
      twoKSettings: null,
    };
  },
  props: {},
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMainMenu();
    this.loadMannschaften();
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    if (
      !this.activeMenuItem ||
      (this.activeMenuItem.name == "pokal" && !this.currentTeamPlaysCup())
    ) {
      this.activeMenuItem = gameDaysMenuItem;
    }
    var currentGameIndex = localStorage.getItem("currentGameDayIndex");
    if (currentGameIndex) {
      this.gameDayViewIndex = currentGameIndex;
    }
    this.updateMainMenu();
    this.loadMannschaften();
  },
  methods: {
    edit(data) {
      this.editPlayer = data;
      this.signOffPlayer = null;
    },
    cancelEdit() {
      this.editPlayer = null;
      this.signOffPlayer = null;
    },
    signOff(data) {
      this.editPlayer = null;
      this.signOffPlayer = data;
    },
    executeSignOff() {
      if (this.signOffPlayer) {
        ServerConnection.put("teamadmin/player/signOff?playerId=" + this.signOffPlayer.id)
          .then(() => {
            this.signOffPlayer = null;
            this.mannschaften = [];
            this.loadMannschaften();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    save() {
      if (this.editPlayer) {
        ServerConnection.put("teamadmin/player/", JSON.stringify(this.editPlayer))
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response);
            }
          })
          .then(() => {
            this.editPlayer = null;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadMannschaften() {
      if (!this.mannschaften || this.mannschaften.length == 0) {
        ServerConnection.get("teamadmin/mannschaften")
          .then((response) => response.data)
          .then((data) => {
            this.mannschaften = data;
            if (this.mannschaften.length == 1) {
              this.currentMannschaft = this.mannschaften[0];
              this.loadTeamData();
            } else {
              var teamId = (this.currentMannschaft = localStorage.getItem(
                "currentMannschaftId"
              ));

              if (teamId) {
                var currentTeam = this.mannschaften.find((team) => team.id == teamId);
                if (currentTeam) {
                  this.currentMannschaft = currentTeam;
                  this.loadTeamData();
                }
              }
            }
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadTeamData() {
      if (this.currentMannschaft && this.currentMannschaft.id) {
        ServerConnection.get(
          "conversations/team/" + this.currentMannschaft.id + "?offSet=0&limit=1000"
        )
          .then((response) => response.data)
          .then((data) => {
            this.teamConversations = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
        ServerConnection.get("twoK/settings?teamId=" + this.currentMannschaft.id)
          .then((resp) => {
            this.twoKSettings = resp.data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    updateMainMenu() {
      if (
        this.currentMannschaft &&
        this.activeMenuItem &&
        this.activeMenuItem.name == "spielort"
      ) {
        if (this.$refs.spielortRecordView.editMode) {
          this.menuService.setMainMenuItemNames([
            {
              name: "save",
              command: () => {
                this.$refs.spielortRecordView.saveRecord();
                this.$refs.spielortRecordView.switchEditMode(false);
                this.updateMainMenu();
              },
            },
            {
              name: "cancel",
              command: () => {
                this.$refs.spielortRecordView.reloadRecord();
                this.updateMainMenu();
              },
            },
          ]);
        } else {
          this.menuService.setMainMenuItemNames([
            "settings",
            "help",
            "sidebar",
            "logout",
            {
              name: "edit",
              command: () => {
                this.$refs.spielortRecordView.switchEditMode(true);
                this.updateMainMenu();
              },
            },
          ]);
        }
      } else if (
        this.currentMannschaft &&
        this.activeMenuItem &&
        this.activeMenuItem.name == "contactperson"
      ) {
        if (this.$refs.contactpersonRecordView.editMode) {
          this.menuService.setMainMenuItemNames([
            {
              name: "save",
              command: () => {
                this.$refs.contactpersonRecordView.saveRecord();
                this.$refs.contactpersonRecordView.switchEditMode(false);
                this.updateMainMenu();
              },
            },
            {
              name: "cancel",
              command: () => {
                this.$refs.contactpersonRecordView.reloadRecord();
                this.updateMainMenu();
              },
            },
          ]);
        } else {
          this.menuService.setMainMenuItemNames([
            "settings",
            "help",
            "sidebar",
            "logout",
            {
              name: "edit",
              command: () => {
                this.$refs.contactpersonRecordView.switchEditMode(true);
                this.updateMainMenu();
              },
            },
          ]);
        }
      } else {
        this.menuService.setMainMenuItemNames(["settings", "sidebar", "help", "logout"]);
      }

      if (this.currentMannschaft) {
        this.menuService.setSidebarItems([
          {
            label: "Spieler zur Mannschaft hinzufügen",
            icon: "pi pi-link",
            command: () => {
              this.wizardService.navigateToWizard(
                this.$router,
                "addPlayer",
                {
                  baseRecord: this.currentMannschaft,
                },
                this.$route.fullPath
              );
            },
          },
        ]);
      } else {
        this.menuService.setSidebarItems([]);
      }
    },
    onItemClick(item) {
      this.activeMenuItem = item;
    },
    handleMenuItemAktiveByName(name, items) {
      if (items && items.length > 0) {
        for (const item of items) {
          if (item && item.items && item.items.length && item.items.length.length > 0) {
            item.active = this.handleMenuItemAktiveByName(name, item.items);
          } else {
            item.active = item.name == name;
          }
        }
      }
    },
    currentMannschaftChanged() {
      if (this.currentMannschaft) {
        localStorage.setItem("currentMannschaftId", this.currentMannschaft.id);
        this.loadTeamData();
      }
    },
    change2KSettings() {
      if (this.currentMannschaft && this.currentMannschaft.id) {
        ServerConnection.put(
          "twoK/settings?teamId=" + this.currentMannschaft.id,
          this.twoKSettings
        )
          .then((resp) => {
            this.twoKSettings = resp.data;
            this.display2KSettingsDialog = true;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    gameDayViewIndexChanged(gameDayViewIndex) {
      this.gameDayViewIndex = gameDayViewIndex;
    },
    getBadgeCount(item) {
      if (item && item.name == "nachrichten" && this.teamConversations) {
        return this.teamConversations.filter(
          (tm) =>
            !tm.readTimestamp ||
            tm.readTimestamp == null ||
            tm.readTimestamp < tm.updateTimestamp
        ).length;
      }
      return null;
    },
    showConversationDialog(conversation) {
      this.conversation = conversation;
      this.conversation.headerMessage = this.conversation.headerMessage.replaceAll(
        "\\n",
        "<br />"
      );
      this.displayConversationDialog = true;
      ServerConnection.put("conversation/" + this.conversation.id + "/markAsRead")
        .then(() => this.loadConversations())
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    closeConversationDialog(answered) {
      this.displayConversationDialog = false;
      if (answered) {
        this.loadConversations();
      }
    },
    conversationRowClass(row) {
      if (row.answerRequested) {
        if (row.resultingAnswer && row.resultingAnswer != null) {
          return "conversationAnswered";
        }
        return "conversationAnswerRequested";
      }
      if (row.readTimestamp && row.readTimestamp != null) {
        return "conversationRead";
      }
      return "conversationNotRead";
    },
    formatDateTimeInfo(date) {
      if (date && date.length > 0) {
        var pattern = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/;
        return date.replace(pattern, "$3.$2.$1 $4:$5 Uhr");
      }
      return "";
    },
    currentTeamPlaysCup() {
      return this.currentMannschaft && this.currentMannschaft.registeredForCup;
    },
  },
};
</script>

<style lang="css">
.conversationNotRead {
  font-weight: bold;
}
.conversationRead {
  font-weight: normal;
}
.conversationAnswerRequested {
  color: #ff0000 !important;
  font-weight: bold;
}
.conversationAnswered {
  font-weight: normal;
}
.p-buttonset .p-button:not(:last-child) {
  border: 1px solid #ced4da;
}
@media screen and (max-width: 500px) {
  /* Darken the line between the entries in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: right !important;
    border: 1px solid #555;
    border-width: 0 0 2px 0;
  }
  /* Enlarge distance between each entry in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td:is(:last-child) {
    margin-bottom: 0.7rem;
    padding-bottom: 1rem;
  }
}
.p-message .p-message-wrapper {
  padding: 0.5rem 1rem !important;
}
.p-overlay-badge .p-badge {
  transform: translate(20%, -20%) !important;
}
</style>
