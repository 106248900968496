<template>
  <div v-if="totalGameDaysCount > 0">
    <Paginator
      v-model:first="currentIndex"
      :rows="1"
      :pageLinkSize="totalGameDaysCount"
      template="PageLinks"
      :totalRecords="totalGameDaysCount"
      @page="onPage($event)"
    >
    </Paginator>
    <h4>{{ currentGameDay.spieltag }}. Spieltag</h4>
    <div
      class="align-items-center justify-content-center gamesView"
      style="display: flex"
    >
      <DataTable
        class="p-datatable-sm"
        :value="currentGameDay.alleSpiele"
        :rowClass="rowClass"
        responsiveLayout="stack"
        breakpoint="550px"
      >
        <Column header="Datum">
          <template #body="slotProps">
            {{ slotProps.data.datum }}
            {{
              slotProps.data.uhrzeit && slotProps.data.uhrzeit != null
                ? slotProps.data.uhrzeit
                : ""
            }}
          </template>
        </Column>
        <Column header="Heim">
          <template #body="slotProps">
            {{ slotProps.data.heimmannschaft }}
            <Button
              v-if="showPlayersDialogButton(slotProps.data, true)"
              style="margin: 2px 10px; vertical-align: middle"
              icon="pi pi-users"
              v-tooltip.top="'Spieler-Übersicht'"
              class="p-button-rounded p-button-info mb2"
              @click="showPlayersDialog(slotProps.data, true)"
            />
          </template>
        </Column>
        <Column field="ergebnis" header="Ergebnis" style="text-align: center" />
        <Column header="Gast">
          <template #body="slotProps">
            {{ slotProps.data.gastmannschaft }}
            <Button
              v-if="showPlayersDialogButton(slotProps.data, false)"
              style="margin: 2px 10px; vertical-align: middle"
              icon="pi pi-users"
              v-tooltip.top="'Spieler-Übersicht'"
              class="p-button-rounded p-button-info mb2"
              @click="showPlayersDialog(slotProps.data, false)"
            />
          </template>
        </Column>
        <Column header="Spielstatus">
          <template #body="slotProps">
            {{ translateGameStatus(slotProps.data) }}
          </template>
        </Column>
        <Column header="">
          <template #body="slotProps">
            <div v-if="ownGameHomeTodayOrPast(slotProps.data)">
              <Button
                style="margin: 2px 2px"
                icon="pi pi-pencil"
                v-tooltip.top="'Editieren'"
                class="p-button-rounded p-button-warning mb2"
                @click="editGame(slotProps.data)"
              />
              <Button
                style="margin: 2px 2px"
                icon="pi pi-eye"
                v-tooltip.top="'Details'"
                class="p-button-rounded p-button-success mb2"
                @click="showGame(slotProps.data)"
              />
            </div>
            <!--
              <Button
                v-else-if="ownGameHomeTodayOrPast(slotProps.data)"
                style="margin: 2px 2px"
                icon="pi pi-pencil"
                v-tooltip.top="'Editieren'"
                class="p-button-rounded p-button-warning mb2"
                @click="editGame(slotProps.data)"
              />
               -->

            <div v-else-if="ownGameFuture(slotProps.data)">
              <Button
                style="margin: 2px 2px"
                icon="pi pi-calendar-times"
                v-tooltip.top="'Verlegen'"
                class="p-button-rounded p-button-warning mb2"
                @click="this.handleGameRequest(slotProps.data, 'changeDateRequest')"
              />
              <Button
                style="margin: 2px 2px"
                icon="pi pi-code"
                v-tooltip.top="'Heimrecht tauschen'"
                class="p-button-rounded p-button-warning mb2"
                @click="this.handleGameRequest(slotProps.data, 'switchHomeGuestRequest')"
              />
            </div>

            <div v-else>
              <Button
                v-if="gamePlayed(slotProps.data)"
                style="margin: 2px 2px"
                icon="pi pi-eye"
                v-tooltip.top="'Details'"
                class="p-button-rounded p-button-success mb2"
                @click="showGame(slotProps.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      id="showPlayersDialog"
      modal
      v-model:visible="displayPlayersDialog"
      style="min-width: 300px; max-width: 600px"
      :closable="true"
    >
      <template #header>
        <div
          class="align-items-center justify-content-center"
          style="font-size: 16px; text-align: center; width: 100%; margin: 5px 15px"
        >
          Gemeldete Spieler der Mannschaft <br />
          {{ teamToShowPlayer }}
        </div>
      </template>
      <div class="flex flex-column align-items-center justify-content-center">
        <DataTable
          class="p-datatable-sm"
          :value="playersToShow"
          style="margin-top: 5px; width: 100%"
        >
          <Column field="name" header="Name"></Column>
        </DataTable>
      </div>
    </Dialog>
  </div>
  <div v-else>
    <ProgressSpinner style="margin-top: 20px" />
  </div>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import { useWizardService } from "../../services/WizardService/UseWizardService";
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
export default {
  name: "TeamGameDayView",
  components: {
    Paginator,
    DataTable,
    Column,
    Button,
    ProgressSpinner,
    Dialog,
  },
  data() {
    return {
      wizardService: useWizardService(),
      gameDays: [],
      currentGameDay: {},
      oldMannschaft: {},
      currentIndex: -1,
      playersToShow: [],
      teamToShowPlayer: "",
      displayPlayersDialog: false,
    };
  },
  computed: {
    totalGameDaysCount() {
      return this.gameDays ? this.gameDays.length : 0;
    },
  },
  props: {
    mannschaft: {},
  },
  mounted() {
    this.loadGameDays();
  },
  updated() {
    this.loadGameDays();
  },
  methods: {
    loadGameDays() {
      if (!this.oldMannschaft || this.oldMannschaft != this.mannschaft) {
        this.oldMannschaft = this.mannschaft;

        ServerConnection.get(
          "data/saison/current/mannschaft/" + this.mannschaft.id + "/games"
        )
          .then((response) => response.data)
          .then((data) => {
            this.gameDays = data;
            var storedCurrentIndex = localStorage.getItem("currentGameDayIndex");
            if (storedCurrentIndex) {
              localStorage.removeItem("currentGameDayIndex");
              this.currentIndex = parseInt(storedCurrentIndex);
              this.currentGameDay = this.gameDays[this.currentIndex];
            } else {
              this.currentGameDay = this.gameDays.find(
                (gameDay) => gameDay.istNaechterSpieltag
              );
              if (this.currentIndex < 0) {
                this.currentIndex =
                  this.gameDays && this.currentGameDay
                    ? this.gameDays.indexOf(this.currentGameDay)
                    : 0;
              }
            }
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    editGame(data) {
      localStorage.setItem("currentGameDayIndex", this.currentIndex);
      this.$router.push("/game/edit/" + data.id);
    },
    showGame(data) {
      localStorage.setItem("currentGameDayIndex", this.currentIndex);
      this.$router.push("/game/view/" + data.id);
    },
    ownGameHomeTodayOrPast(data) {
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      var gameDate = new Date(data.datum.replace(pattern, "$3-$2-$1"));
      return (
        data.heimmannschaft === this.mannschaft.name &&
        new Date() >= gameDate &&
        this.statusAllowsEditing(data)
      );
    },
    ownGameFuture(data) {
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      var gameDate = new Date(data.datum.replace(pattern, "$3-$2-$1"));
      return (
        this.ownGame(data) && new Date() < gameDate && this.statusAllowsEditing(data)
      );
    },
    ownGame(data) {
      return (
        data.heimmannschaft === this.mannschaft.name ||
        data.gastmannschaft === this.mannschaft.name
      );
    },
    statusAllowsEditing(data) {
      if (data && data.status) {
        switch (data.status) {
          case "ToPlay":
          case "BeingPlayed":
          case "WaitingForResults":
          case "WaitingForImage":
          case "WaitingForHomeApproval":
          case "PostPoned":
            return true;
          default:
            return false;
        }
      }
      return false;
    },
    gamePlayed(data) {
      return data.ergebnis && data.ergebnis != " : ";
    },
    rowClass(data) {
      return this.ownGame(data) ? "owngame" : null;
    },
    getSplitButtonItems(game, itemIds) {
      var splitButtonItems = [];
      if (!itemIds || itemIds == null || itemIds.length <= 0) {
        return splitButtonItems;
      }
      if (itemIds.includes("show")) {
        splitButtonItems.push({
          label: "Anzeigen",
          icon: "pi pi-eye",
          command: () => {
            this.showGame(game);
          },
        });
      }
      if (itemIds.includes("edit")) {
        splitButtonItems.push({
          label: "Editieren",
          icon: "pi pi-pencil",
          command: () => {
            this.editGame(game);
          },
        });
      }
      if (itemIds.includes("changeDate")) {
        splitButtonItems.push({
          label: "Verlegen",
          icon: "pi pi-calendar-times",
          command: () => {
            this.handleGameRequest(game, "changeDateRequest");
          },
        });
      }
      if (itemIds.includes("switch")) {
        splitButtonItems.push({
          label: "Heimrecht tauschen",
          icon: "pi pi-code",
          command: () => {
            this.handleGameRequest(game, "switchHomeGuestRequest");
          },
        });
      }
      return splitButtonItems;
    },
    handleGameRequest(game, wizardId) {
      this.wizardService.navigateToWizard(
        this.$router,
        wizardId,
        {
          baseRecord: game,
          teamId: this.mannschaft.id,
          numberOfGameDay: this.currentGameDay.spieltag,
        },
        this.$route.fullPath
      );
    },
    onPage(event) {
      this.currentIndex = event.page;
      this.currentGameDay = this.gameDays[event.page];
      localStorage.setItem("currentGameDayIndex", this.currentIndex);
      this.$emit("gameDayViewIndexChanged", this.currentIndex);
    },
    translateGameStatus(game) {
      if (game && game.status) {
        switch (game.status) {
          case "ToPlay":
            return "Zu spielen";
          case "BeingPlayed":
            return "Wird gerade gespielt";
          case "WaitingForResults":
            return "Noch kein Endergebnis";
          case "WaitingForImage":
            return "Noch kein Bild des Endergebnisses";
          case "WaitingForHomeApproval":
            return "In Bestätigung durch Heim";
          case "InDiscussion":
            return "In Abstimmung";
          case "WaitingForGuestApproval":
            return "In Bestätigung durch Gast";
          case "WaitingForGameManagerApproval":
            return "In Kontrolle durch Spielleiter";
          case "Played":
            return "Gespielt";
          case "PostPoned":
            return "Verlegt";
          case "Unrated":
            return "Keine Wertung";
          case "ChangeRequested":
            return "Änderung beim Gegner angefragt";
        }
      }
      return "";
    },
    showPlayersDialogButton(game, isHome) {
      if (this.mannschaft && this.gameDays.length > 0) {
        var showAllOpponents = true;
        if (isHome) {
          if (this.mannschaft.id != game.gastmannschaftId || game.heimmannschaftId < 0) {
            return false;
          }
        } else {
          if (this.mannschaft.id != game.heimmannschaftId || game.gastmannschaftId < 0) {
            return false;
          }
        }
        if (showAllOpponents) {
          return true;
        }
        var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        var gameDate = new Date(game.datum.replace(pattern, "$3-$2-$1"));
        var currentDate = new Date();

        if (currentDate > gameDate) {
          return false;
        }
        var nextGame;
        this.gameDays.forEach((gd) => {
          var nextGames = gd.alleSpiele.filter(
            (game2) =>
              this.mannschaft.id == game2.gastmannschaftId ||
              this.mannschaft.id == game2.heimmannschaftId
          );
          nextGames.forEach((game2) => {
            var gameDate = new Date(game2.datum.replace(pattern, "$3-$2-$1"));
            if (gameDate > currentDate) {
              if (nextGame) {
                var nexGameDate = new Date(nextGame.datum.replace(pattern, "$3-$2-$1"));
                if (gameDate < nexGameDate) {
                  nextGame = game2;
                }
              } else {
                nextGame = game2;
              }
            }
          });
        });
        return nextGame && game.id == nextGame.id;
      }
      return false;
    },
    showPlayersDialog(game, isHome) {
      if (this.mannschaft) {
        var url;
        if (isHome) {
          url = "data/game/" + game.id + "/players/home";
          this.teamToShowPlayer = game.heimmannschaft;
        } else {
          url = "data/game/" + game.id + "/players/guest";
          this.teamToShowPlayer = game.gastmannschaft;
        }
        ServerConnection.get(url)
          .then((response) => response.data)
          .then((data) => {
            this.playersToShow = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
        this.displayPlayersDialog = true;
      }
    },
  },
};
</script>

<style lang="css">
.owngame {
  background-color: #b9c4ff !important;
}
@media (max-width: 550px) {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 2px 7px !important;
  }
  .p-datatable[pv_id_2] .p-datatable-tbody > tr > td:is(:last-child) {
    justify-content: flex-end !important;
  }
  .gamesView {
    display: block !important;
  }

  /* Darken the line between the entries in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: right !important;
    border: 1px solid #555;
    border-width: 0 0 2px 0;
  }
  /* Enlarge distance between each entry in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td:is(:last-child) {
    margin-bottom: 0.7rem;
    padding-bottom: 1rem;
  }
}
</style>
